import { useRef, useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Offcanvas from "react-bootstrap/Offcanvas";

const Queue = ({ projectId, projectName }) => {
  const token = localStorage.getItem("eapi-token");
  const startDate = useRef("");
  const endDate = useRef("");

  const [job, setJob] = useState();
  const [showHistory, setShowHistory] = useState(false);
  const [history, setHistory] = useState([]);
  const [errorMessage, setErrorMessage] = useState();

  const handleClose = () => setShowHistory(false);
  const handleShow = () => setShowHistory(true);

  const newJob = async (e) => {
    e.preventDefault();

    try {
      const url = new URL("/queue/jobs/new", process.env.REACT_APP_QUEUE_URL);

      const params = {
        projectId: projectId,
        projectName: projectName,
        startDate: startDate.current,
        endDate: endDate.current,
      };

      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );

      const newJob = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: token,
        },
        body: url.searchParams,
      });

      if (newJob?.status === 401) {
        setErrorMessage(
          "The client token has expired, please log out and back in."
        );
      }
      setErrorMessage();
      const json = await newJob.json();

      setJob(json);

      return;
    } catch (error) {
      setErrorMessage("A serious error has occurred while creating a new job.");
      console.error(error);
    }
  };

  const getHistory = async () => {
    const url = new URL("/queue/jobs", process.env.REACT_APP_QUEUE_URL);

    try {
      const request = await fetch(url, {
        method: "GET",
        headers: {
          token: token,
        },
      });

      if (request.status === 401) {
        setErrorMessage(
          "The client token has expired, please log out and back in."
        );
      }
      setErrorMessage();
      const json = await request.json();

      setHistory(json);
      return;
    } catch (error) {
      console.error(error);
      setErrorMessage("There was a problem loading the history.");
    }
  };

  useEffect(() => {
    const getStatus = async () => {
      //if (!projectId) return;
      setErrorMessage();
      const url = new URL(
        `/queue/jobs/status/p/${projectId}`,
        process.env.REACT_APP_QUEUE_URL
      );
      try {
        const request = await fetch(url, {
          method: "GET",
          headers: {
            token: token,
          },
        });

        if (request?.status === 401) {
          setErrorMessage(
            "The client token has expired, please log out and back in."
          );
        }
        let json;
        if (request.status >= 200 && request.status <= 299) {
          json = await request.json();
        }
        setJob(json);
        return;
      } catch (error) {
        setErrorMessage("A serious error has occurred.");
        console.error(error);
        return;
      }
    };

    if (projectId) {
      getStatus();
      const interval = setInterval(() => getStatus(), 30000);
      return () => clearInterval(interval);
    }
  }, [projectId, token]);
  return (
    <>
      {job?.id ? (
        <>
          <ProgressBar
            striped
            animated
            now={(job?.completed_rows / job?.total_rows) * 100 + 10}
            label={`${job?.completed_rows} of ${job?.total_rows}`}
          />
          <Row>
            <Col>
              Updates every 30 seconds.{" "}
              <span style={{ color: "red" }}>{errorMessage}</span>
            </Col>
          </Row>
          <Table className="m-0 p-1">
            <tbody>
              <tr key={job?.id}>
                <th>Current Status</th>
                <td>{job?.job_status}</td>
                <th>Ticket Discrepencies</th>
                <td>{job?.edited_rows}</td>
                <th>Started</th>
                <td>{job?.job_started}</td>
                <td>
                  <Button
                    variant="info"
                    size="sm"
                    className="m-0 p-1"
                    style={{ color: "white" }}
                    onClick={handleShow}
                  >
                    History
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      ) : (
        <>
          <Row>
            <Form.Label htmlFor="job-queue">
              <h5>Send Project to Job Queue</h5>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text>Start Date</InputGroup.Text>
              <Form.Control
                type="date"
                value={startDate.current.value}
                onChange={(e) => {
                  startDate.current = e.target.value;
                }}
              ></Form.Control>
              <InputGroup.Text>End Date</InputGroup.Text>
              <Form.Control
                type="date"
                value={endDate.current.value}
                onChange={(e) => {
                  endDate.current = e.target.value;
                }}
              ></Form.Control>
              <Button
                variant="info"
                style={{ color: "white" }}
                onClick={handleShow}
              >
                History
              </Button>
              <Button
                style={{ color: "white" }}
                onClick={(e) => {
                  newJob(e);
                }}
              >
                Start Job
              </Button>
            </InputGroup>
          </Row>
          <Row>
            <div style={{ color: "red" }}>{errorMessage}</div>
          </Row>
        </>
      )}
      <Offcanvas
        show={showHistory}
        onHide={handleClose}
        placement="bottom"
        onShow={() => {
          getHistory();
        }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h2>All History</h2>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Table>
            <thead style={{ textAlign: "center" }}>
              <th>Project</th>
              <th>Started</th>
              <th>Completed</th>
              <th>Total Tickets</th>
              <th>Edited Tickets</th>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {history.map((h) => {
                return (
                  <tr key={h?.id}>
                    <th>{h.project_name}</th>
                    <td>{h.job_started}</td>
                    <td>{h.job_completed}</td>
                    <td>{h.total_rows}</td>
                    <td>{h.edited_rows}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Queue;
